/* MapComponent.css */

.map-container {
  position: relative;
  width: 100%;
  height: 500px;
}

.map-box {
  width: 100%;
  height: 100%;
}
