#sidebar {
  background: #020509;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  transition: all 0.5s;
  width: 90%;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
}


.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer;
}

.active{
  background-color: #393d42;
}
.sidebar__link:hover{
  
    background-color: #393d42;

}

.signout{
  margin-top: 100px;
}


.active_menu_link a {
  color: white !important
}

.sidebar__link > a {
  text-decoration: none;
  color: white;
  font-weight: 700;
}
.alink{
  text-decoration: none;
}

.sidebar_icon {
  margin-right: 10px;
  font-size: 18px;
}




.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  transition: all ease 0.5s;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
    width: 50%;
    height: 100vh;
  }



  .sidebar__title > i {
    display: inline;
  }
}
