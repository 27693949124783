main {

    grid-area: main;
    overflow-y: auto;
  }


  .dash{
      /* width: 200px;
      height: 200px; */
      /* border: solid 3px black; */
      /* color: white; */
      margin-left: 10px;
      margin-top: 10px;
  }