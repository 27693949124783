.app_navbar {
  background: #ffffff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  display: none;
}

.nav_icon {
  display: none;
}


@media only screen and (max-width: 978px) {
  .nav_icon {
    display: inline;
    /* margin-right: 30px; */
  }
  .app_navbar{
    display: block;
  }
}


