/*********** Filters CSS ***********/

.filters-container {
  padding: 20px;
}

.filters-title {
  margin-bottom: 20px;
}

.filters-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-card {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom:'dotted';
}

.filter-options-list ul {
  list-style-type: none;
  padding: 0;
}

.filter-options-list ul li {
  margin: 5px 0;
}

.filter-status {
  display: flex;
  align-items: center;
}

.filter-status button {
  margin-left: 10px;
}

.btn-success {
  background-color: green;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

.btn-danger {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .filter-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-status {
    margin-top: 10px;
  }
}
