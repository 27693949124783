.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(46, 44, 44, 0.753);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner{
    position: relative;
    padding: 20px 20px;
    width: 40%;
    border-radius: 10px;
    /* max-width: 640px; */
    background-color: white;
}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
}

.form-content{
    margin: 10px 0;
}

.form-content input{
    padding: 6px 7px;
    /* width:90%; */
    border-radius: 5px;
    border: rgba(92, 90, 90, 0.616) 2px solid;
}
.form-content label{
    font-size: 15px;
    /* padding: 60px; */
}
.form-content button{
    width: 86%;
    margin-top: 10px;
    background-color: black;
    color: white;
    padding: 10px 0;
    border-radius: 5px;
}

@media only screen and (max-width: 978px) {
    .popup-inner{
        width: 90%;
    }
}