@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
* {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.error{
  color: red;
}
.success{
  color:green;
}

.app_container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}

@media only screen and (max-width: 978px) {
  .app_container {
  margin: 0 40px;

    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
}


.app_navbar {
  background: #ffffff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  display: none;
}

.nav_icon {
  display: none;
}


@media only screen and (max-width: 978px) {
  .nav_icon {
    display: inline;
    /* margin-right: 30px; */
  }
  .app_navbar{
    display: block;
  }
}



#sidebar {
  background: #020509;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  transition: all 0.5s;
  width: 90%;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
}


.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer;
}

.active{
  background-color: #393d42;
}
.sidebar__link:hover{
  
    background-color: #393d42;

}

.signout{
  margin-top: 100px;
}


.active_menu_link a {
  color: white !important
}

.sidebar__link > a {
  text-decoration: none;
  color: white;
  font-weight: 700;
}
.alink{
  text-decoration: none;
}

.sidebar_icon {
  margin-right: 10px;
  font-size: 18px;
}




.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  transition: all ease 0.5s;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
    width: 50%;
    height: 100vh;
  }



  .sidebar__title > i {
    display: inline;
  }
}

main {

    grid-area: main;
    overflow-y: auto;
  }

.coaches_title{
    display:flex;
    /* border: 4px red solid; */
    justify-content: space-between;
}

.coaches_button{
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 40px;
    width:24.5% ;
}
#table_img {
    width:  50px;
    height: 50px;
    border-radius: 5px;
    overflow-x:hidden;
    overflow-y:hidden;
    margin-right: 10px;
    
  }

.filter{
    margin: 20px 10px;
}

.filter select{
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}

.filter input{
    float: right;
    margin-right: 30px;
    padding: 5px;
    width: 10%;
    border-radius: 5px;
}

.link{
    text-decoration: none;
}

.pagnation{
    /* border: red 4px solid; */
    /* display: flex;
    justify-content: center; */
    text-align: center;
}
.button_unactive{
    /* border: green 5px solid; */
    /* display: flex;
    justify-content: space-evenly; */
    margin: 5px;
    background-color: white;
    padding: 5px 9px;
    /* border: black 2px solid; */
    border: 2px rgb(124, 120, 120) solid;
    color: rgb(102, 101, 101);
    border-radius: 5px;
}

.button_active{
    color: black;
    border: black 2px solid;
    margin: 5px;
    background-color: white;
    padding: 5px 9px;
  
    border-radius: 5px;
}

.icon-cursor{
    cursor: pointer;
}

@media only screen and (max-width: 978px) {
    .coaches_title{
        flex-direction: column;
    }
    .coaches_title button{
        width: 40%;
    }

    .filter{
        display: flex;
        flex-direction: column;
    }

   #table_img{
       width: 25px;
   }
   td,th{
       font-size: 10px;
   }

    .filter select{
        margin-right: 0;
        width: 30%;
    }
    .filter input {
        width: 50%;
    }
  }


.hiddenRow {
    padding: 0 !important;
}

.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(46, 44, 44, 0.753);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner{
    position: relative;
    padding: 20px 20px;
    width: 40%;
    border-radius: 10px;
    /* max-width: 640px; */
    background-color: white;
}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.form-content{
    margin: 10px 0;
}

.form-content input{
    padding: 6px 7px;
    /* width:90%; */
    border-radius: 5px;
    border: rgba(92, 90, 90, 0.616) 2px solid;
}
.form-content label{
    font-size: 15px;
    /* padding: 60px; */
}
.form-content button{
    width: 86%;
    margin-top: 10px;
    background-color: black;
    color: white;
    padding: 10px 0;
    border-radius: 5px;
}

@media only screen and (max-width: 978px) {
    .popup-inner{
        width: 90%;
    }
}
.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(46, 44, 44, 0.753);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner{
    position: relative;
    padding: 20px 20px;
    width: 40%;
    border-radius: 10px;
    /* max-width: 640px; */
    background-color: white;
}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
}

.form-content{
    margin: 10px 0;
}

.form-content input{
    padding: 6px 7px;
    /* width:90%; */
    border-radius: 5px;
    border: rgba(92, 90, 90, 0.616) 2px solid;
}
.form-content label{
    font-size: 15px;
    /* padding: 60px; */
}
.form-content button{
    width: 86%;
    margin-top: 10px;
    background-color: black;
    color: white;
    padding: 10px 0;
    border-radius: 5px;
}

@media only screen and (max-width: 978px) {
    .popup-inner{
        width: 90%;
    }
}
.pagination{
    text-align: center;
   margin: 20px 30px;
   display: flex;
   justify-content: center;
}
main {

    grid-area: main;
    overflow-y: auto;
  }
  * {
      /* margin: 0;
      padding: 0; */
      overflow-x: hidden;
  }

  .head_img{
      width: 130px;
      height: 130px;
      border-radius: 5px;
      overflow-x:hidden;
      overflow-y:hidden;
  }

.coaches_title{
    display:flex;
    /* border: 4px red solid; */
    justify-content: space-between;
}
#back_btn{
    padding: 5px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    background-color: black;
}

.coaches_button{
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 50px;
}
#table_img {
    width:  50px;
    height: 50px;
    border-radius: 5px;
    overflow-x:hidden;
    overflow-y:hidden;
    margin-right: 5px;
  }

.filter{
    margin: 20px 10px;
}

.filter select{
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}

.filter input{
    float: right;
    margin-right: 30px;
    padding: 5px;
    width: 10%;
    border-radius: 5px;
}

.pagnation{
    /* border: red 4px solid; */
    /* display: flex;
    justify-content: center; */
    text-align: center;
}
.button_unactive{
    /* border: green 5px solid; */
    /* display: flex;
    justify-content: space-evenly; */
    margin: 5px;
    background-color: white;
    padding: 5px 9px;
    /* border: black 2px solid; */
    border: 2px rgb(124, 120, 120) solid;
    color: rgb(102, 101, 101);
    border-radius: 5px;
}

.button_active{
    color: black;
    border: black 2px solid;
    margin: 5px;
    background-color: white;
    padding: 5px 9px;
  
    border-radius: 5px;
}

@media only screen and (max-width: 978px) {
    .coaches_title{
        flex-direction: column;
    }
    .coaches_title button{
        width: 40%;
    }

    .filter{
        display: flex;
        flex-direction: column;
    }

   #table_img{
       width: 25px;
   }

   .head_img{
       width: 45px;
   }

    .filter select{
        margin-right: 0;
        width: 30%;
    }
    .filter input {
        width: 50%;
    }
  }

/* MapComponent.css */

.map-container {
  position: relative;
  width: 100%;
  height: 500px;
}

.map-box {
  width: 100%;
  height: 100%;
}

main {

    grid-area: main;
    overflow-y: auto;
  }


  .dash{
      /* width: 200px;
      height: 200px; */
      /* border: solid 3px black; */
      /* color: white; */
      margin-left: 10px;
      margin-top: 10px;
  }
.login_body{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background-color: rgba(46, 44, 44, 0.753); */
    background-color: White;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_inner{
    /* position: relative; */
    padding: 20px 20px;
    width: 40%;
    border-radius: 10px;
    /* max-width: 640px; */
    background-color: white;
}
/* 
.login_inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
} */

.form-content{
    margin: 10px 0;
}

.form-content input{
    padding: 6px 7px;
    width:70%;
    border-radius: 5px;
    border: rgba(92, 90, 90, 0.616) 2px solid;
}
.form-content label{
    font-size: 15px;
    /* padding: 60px; */
}
.form-content button{
    width: 40%;
    margin-top: 10px;
    background-color: black;
    color: white;
    padding: 10px 0;
    border-radius: 5px;
}

@media only screen and (max-width: 978px) {
    .popup-inner{
        width: 90%;
    }
}
/*********** Filters CSS ***********/

.filters-container {
  padding: 20px;
}

.filters-title {
  margin-bottom: 20px;
}

.filters-list {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.filter-card {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom:'dotted';
}

.filter-options-list ul {
  list-style-type: none;
  padding: 0;
}

.filter-options-list ul li {
  margin: 5px 0;
}

.filter-status {
  display: flex;
  align-items: center;
}

.filter-status button {
  margin-left: 10px;
}

.btn-success {
  background-color: green;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

.btn-danger {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .filter-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-status {
    margin-top: 10px;
  }
}

/* PlayerLocationSearch.css */

.main {
    padding: 20px;
  }
  
  .player-location-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .filter input[type="text"],
  .filter input[type="number"] {
    padding: 8px;
    font-size: 16px;
  }
  
  .filter button {
    padding: 8px 12px;
    font-size: 16px;
  }
  
  .toggles {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggles label {
    margin-right: 15px;
    font-size: 16px;
  }
  
  .results-table {
    margin-top: 20px;
  }
  
  .results-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .results-table th,
  .results-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .results-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .pagination {
    margin-top: 10px;
    font-size: 16px;
  }
  
