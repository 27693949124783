main {

    grid-area: main;
    overflow-y: auto;
  }
  * {
      /* margin: 0;
      padding: 0; */
      overflow-x: hidden;
  }

  .head_img{
      width: 130px;
      height: 130px;
      border-radius: 5px;
      overflow-x:hidden;
      overflow-y:hidden;
  }

.coaches_title{
    display:flex;
    /* border: 4px red solid; */
    justify-content: space-between;
}
#back_btn{
    padding: 5px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    background-color: black;
}

.coaches_button{
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 50px;
}
#table_img {
    width:  50px;
    height: 50px;
    border-radius: 5px;
    overflow-x:hidden;
    overflow-y:hidden;
    margin-right: 5px;
  }

.filter{
    margin: 20px 10px;
}

.filter select{
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}

.filter input{
    float: right;
    margin-right: 30px;
    padding: 5px;
    width: 10%;
    border-radius: 5px;
}

.pagnation{
    /* border: red 4px solid; */
    /* display: flex;
    justify-content: center; */
    text-align: center;
}
.button_unactive{
    /* border: green 5px solid; */
    /* display: flex;
    justify-content: space-evenly; */
    margin: 5px;
    background-color: white;
    padding: 5px 9px;
    /* border: black 2px solid; */
    border: 2px rgb(124, 120, 120) solid;
    color: rgb(102, 101, 101);
    border-radius: 5px;
}

.button_active{
    color: black;
    border: black 2px solid;
    margin: 5px;
    background-color: white;
    padding: 5px 9px;
  
    border-radius: 5px;
}

@media only screen and (max-width: 978px) {
    .coaches_title{
        flex-direction: column;
    }
    .coaches_title button{
        width: 40%;
    }

    .filter{
        display: flex;
        flex-direction: column;
    }

   #table_img{
       width: 25px;
   }

   .head_img{
       width: 45px;
   }

    .filter select{
        margin-right: 0;
        width: 30%;
    }
    .filter input {
        width: 50%;
    }
  }
