/* PlayerLocationSearch.css */

.main {
    padding: 20px;
  }
  
  .player-location-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .filter input[type="text"],
  .filter input[type="number"] {
    padding: 8px;
    font-size: 16px;
  }
  
  .filter button {
    padding: 8px 12px;
    font-size: 16px;
  }
  
  .toggles {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggles label {
    margin-right: 15px;
    font-size: 16px;
  }
  
  .results-table {
    margin-top: 20px;
  }
  
  .results-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .results-table th,
  .results-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .results-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .pagination {
    margin-top: 10px;
    font-size: 16px;
  }
  