@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");


* {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.error{
  color: red;
}
.success{
  color:green;
}

.app_container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}

@media only screen and (max-width: 978px) {
  .app_container {
  margin: 0 40px;

    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
}
